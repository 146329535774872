import React from "react"

import styles from "../styles/components/grid.module.scss"
import classNames from "classnames"

const Grid = props => {
  let { maxColumns, children, news } = props

  let dummyItems = []
  for (let i = 0; i < maxColumns - 2; i++) {
    dummyItems.push(
      <div
        key={i}
        className={classNames(styles.dummyGridItem, {
          [styles.threeColumns]: maxColumns >= 3,
          [styles.fourColumns]: maxColumns >= 4,
          [styles.sixColumns]: maxColumns >= 6,
        })}
      />
    )
  }

  return (
    <div
      ref={props.forwardRef}
      className={classNames(styles.grid, {
        [styles.newsGrid]: news,
      })}
    >
      {React.Children.map(children, (child, i) => {
        return (
          <div
            key={i}
            className={classNames(styles.gridItem, {
              [styles.threeColumns]: maxColumns >= 3,
              [styles.fourColumns]: maxColumns >= 4,
              [styles.sixColumns]: maxColumns >= 6,
            })}
          >
            {child}
          </div>
        )
      })}
      {dummyItems}
    </div>
  )
}

export default Grid
