import React from "react"
import { isIE } from "react-device-detect"
import classNames from "classnames"
import CloseIcon from "../images/close-icon.svg"

import styles from "../styles/components/modal.module.scss"

class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.scrollTop = 0
    this.wrapperRef = React.createRef()
  }

  handleScrollClick = event => {
    if (event.target === this.wrapperRef.current) {
      this.props.close()
    }
  }

  handleKeyUp = event => {
    if (this.props.display) {
      if (event.keyCode === 27) this.props.close()
    }
  }

  componentDidMount() {
    window.addEventListener("keyup", this.handleKeyUp)
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp)
  }

  render() {
    let { portfolio, display, close, className, children } = this.props
    // disable scrolling when modal is open
    // doesn't disable scrolling on mobile, but looking
    // at other examples (bootstrap modal) they don't
    // seem to care about this either
    if (typeof document !== "undefined") {
      let html = document.getElementsByTagName("html")[0]
      if (this.props.display) {
        this.scrollTop = -html.getBoundingClientRect().top
        html.style.top = `${html.getBoundingClientRect().top}px`
        html.classList.add(styles.modalOpen)
      } else {
        html.classList.remove(styles.modalOpen)
        html.scrollTop = this.scrollTop
      }
    }
    return (
      <div
        aria-hidden={!display}
        className={classNames(styles.container, {
          [styles.hidden]: !display,
          [styles.portfolio]: portfolio,
          [styles.IEStyles]: isIE,
        })}
      >
        <div className={styles.overlay} onClick={close} />
        <div className={styles.scroll} onClick={this.handleScrollClick}>
          <div className={styles.wrapper} ref={this.wrapperRef}>
            <div className={classNames(className, styles.modal)}>
              {children}
              <button
                aria-label="Close"
                onClick={this.props.close}
                className={styles.close}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal
