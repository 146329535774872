import React from "react"

import styles from "../styles/components/modal-inner.module.scss"

const ModalSection = ({ title, children }) => {
  return (
    <>
      <div className={styles.sectionTitle}>{title}</div>
      <div className={styles.sectionContent}>{children}</div>
    </>
  )
}

const ModalInner = ({ header, footer, aboutBlurb, rightSections }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.modalInnerContainer}>
        {header}
        <div className={styles.infoContainer}>
          <div className={styles.left}>
            <ModalSection title="About">{aboutBlurb}</ModalSection>
          </div>
          <div className={styles.right}>
            {rightSections.map((elem, i) => (
              <ModalSection key={i} title={elem.title}>
                {elem.link && (
                  <a target="_blank" rel="noopener noreferrer" href={elem.link}>
                    {elem.text}
                  </a>
                )}
                {!elem.link &&
                  elem.items.map((item, j) => <p key={j}>{item}</p>)}
              </ModalSection>
            ))}
          </div>
        </div>
        {footer}
      </div>
    </div>
  )
}

export default ModalInner
